import store from "@/store";
import { TeamStatus } from "@/types/teams";
import { computed, onMounted, onUnmounted } from "vue";
const useActiveTeams = () => {
    onMounted(async () => await store.dispatch.teams.subscribe(TeamStatus.Active));
    onUnmounted(async () => await store.dispatch.teams.unsubscribe(TeamStatus.Active));
    return {
        activeTeams: computed(() => store.state.teams.active),
        activeTeamsDataReceived: computed(() => store.state.teams.dataReceived.active)
    };
};
const useWaitingTeams = () => {
    onMounted(async () => await store.dispatch.teams.subscribe(TeamStatus.Waiting));
    onUnmounted(async () => await store.dispatch.teams.unsubscribe(TeamStatus.Waiting));
    return {
        waitingTeams: computed(() => store.state.teams.waiting),
        waitingTeamsDataReceived: computed(() => store.state.teams.dataReceived.waiting)
    };
};
const useFinishedTeams = () => {
    onMounted(async () => await store.dispatch.teams.subscribe(TeamStatus.Finished));
    onUnmounted(async () => await store.dispatch.teams.unsubscribe(TeamStatus.Finished));
    return {
        finishedTeams: computed(() => store.state.teams.finished),
        finishedTeamsDatReceived: computed(() => store.state.teams.dataReceived.finished)
    };
};
const useArchivedTeams = () => {
    onMounted(async () => await store.dispatch.teams.subscribe(TeamStatus.Archived));
    onUnmounted(async () => await store.dispatch.teams.unsubscribe(TeamStatus.Archived));
    return {
        archivedTeams: computed(() => store.state.teams.archived),
        archivedTeamsDataReceived: computed(() => store.state.teams.dataReceived.archived)
    };
};
const useAllTeams = () => {
    return {
        ...useActiveTeams(),
        ...useWaitingTeams(),
        ...useFinishedTeams()
    };
};
export { useActiveTeams, useWaitingTeams, useFinishedTeams, useArchivedTeams, useAllTeams };
