import { defineComponent } from 'vue';
import { TeamStatus } from '@/types/teams';
import { Card, Selector, Expander } from '@/components/UI';
import useRFID from '@/compositions/useRFID';
import { BeatoutLanguage } from '@/types/translations';
export default defineComponent({
    name: 'TeamEdit',
    components: {
        Card,
        Selector,
        Expander
    },
    props: {
        team: {
            type: Object,
            required: true
        }
    },
    setup() {
        const rfidname = 'counterrfid';
        const { currentRFID } = useRFID(rfidname);
        return {
            currentRFID
        };
    },
    data: () => ({
        newMemberName: '',
        options: [{ text: 'Beat Out', value: 'beat out' },
            { text: 'Kraak de Code', value: 'kraak de code' },
            { text: 'Spel Zonder Grenzen', value: 'spel zonder grenzen' }],
        levels: [{ text: 'Difficult', value: 2 },
            { text: 'Normal', value: 0 },
            { text: 'Easy', value: -2 }],
        states: [{ text: 'Waiting', value: 'waiting' },
            { text: 'Active', value: 'active' },
            { text: 'Finished', value: 'finished' }],
        latestRFID: { id: 'none', text: 'none' },
        advancedSettings: false,
    }),
    computed: {
        uniqueBadges() {
            return [...new Set(this.team.members.map(member => member.rfid).filter(rfid => rfid !== ''))];
        },
        specialRfids() {
            return this.$store.direct.state.rfid.specialRFID;
        },
        memberCount: {
            get() {
                return this.team.members.length;
            },
            set(count) {
                while (this.team.members.length < count) {
                    this.team.members.push({ name: `member_${this.team.members.length + 1}`, rfid: '' });
                }
                while (this.team.members.length > count && this.team.members.length > this.uniqueBadges.length) {
                    this.team.members.pop();
                }
            }
        },
        rights() {
            return this.$store.direct.state.auth.rights;
        },
        now() {
            return this.$store.direct.state.clock.now;
        },
        duration: {
            get() {
                return this.team.duration / 60;
            },
            set(value) {
                this.team.duration = value * 60;
            }
        },
        pin: {
            get() {
                return this.team.pin;
            },
            set(value) {
                this.team.pin = Number.parseInt(value);
            }
        },
        language: {
            get() {
                return this.team.language;
            },
            set(value) {
                this.team.language = value;
            }
        },
        languages() {
            // @ts-ignore
            return Object.values(BeatoutLanguage).map((language) => ({ text: language, value: language }));
        },
        left() {
            if (this.team.start_time === null)
                return 0;
            return Math.round((this.team.duration - (this.now - this.team.start_time)) / 60);
        }
    },
    watch: {
        currentRFID(newValue) {
            if (newValue) {
                const found = this.team.members.find(member => member.rfid === newValue.id) !== undefined;
                let valid = newValue.id !== null;
                if (newValue.used) {
                    valid = valid && newValue.used[TeamStatus.Waiting] === null;
                    valid = valid && newValue.used[TeamStatus.Active] === null;
                    valid = valid && newValue.time !== undefined && newValue.time > this.now - 5;
                }
                if (!found && valid) {
                    this.latestRFID = { ...newValue };
                }
            }
        },
        latestRFID(newRFID) {
            if (newRFID.id !== 'none')
                this.addTeamMember(newRFID.id);
        }
    },
    mounted() {
        this.$store.direct.dispatch.rfid.subscribe(undefined);
    },
    beforeUnmount() {
        this.$store.direct.dispatch.rfid.unsubscribe(undefined);
    },
    methods: {
        addTeamMember(rfid) {
            const member = this.team.members.find((member) => !member.rfid);
            if (member === undefined) {
                this.team.members.push({
                    name: this.newMemberName,
                    rfid: rfid //this.latestRFID.id
                });
            }
            else {
                member.rfid = this.latestRFID.id;
            }
            console.log(JSON.stringify(this.team.members));
            this.newMemberName = '';
            this.latestRFID = { id: 'none', rfid: 'none', text: 'none' };
        },
        removeMember(rfid) {
            console.log('REMOVE', rfid, this.team.members);
            const index = this.team.members.findIndex(member => member.rfid === rfid);
            if (index !== undefined) {
                this.team.members.splice(index, 1);
            }
        },
        removeRFID(rfid) {
            this.team.members.filter(member => member.rfid === rfid).forEach(member => member.rfid = '');
        },
        randomRFID() {
            const rfidId = Math.random().toString(36).replace(/[^a-z]+/g, '');
            const rfidText = Math.random().toString(36).replace(/[^a-z]+/g, '');
            this.latestRFID = { id: rfidId, rfid: rfidId, text: rfidText };
        }
    }
});
